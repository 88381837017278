<template lang="pug">
ui-dialog(
  modal
  width="560px"
  :visible-prop="show"
  @content:hide="hide"
)
  template(#header) {{ getLabel(mode, meta?.title) }}
  template(#content)
    meta-editor(
      v-loading="loader"
      :meta="templateMeta"
      @correct="correctChecker"
      @error="errorChecker"
      @reset:all="resetAll"
      @reset:dialog="hide"
    )
  template(#actions)
    ui-button(type="secondary" @click="hide") Отменить
    ui-button(:loader="loader" @click="validateForm") Сохранить
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useForm } from "@/use/other/useForm";
import { tagsToIdList } from "@/utils/searchForm/searchFormWorker";
import { getDefaultTemplateData, getEditMeta, getCopyMeta } from "@/utils/templates/templateFormatter";
import useTemplatesStore from "@/stores/templates/useTemplatesStore";

import MetaEditor from "@/components/forms/MetaEditor.vue";
import UiDialog from "@/components/ui/dialog/UiDialog.vue";
import UiButton from "@/components/ui/button/UiButton.vue";

import type { PropType } from "vue";
import type { ExtendedTemplateI } from "@/stores/templates/SearchTemplatesInterface";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import useSearchStore from "~/stores/search/useSearchStore";

export default defineComponent({
  name: "SaveTemplate",
  components: {
    UiDialog,
    UiButton,
    MetaEditor,
  },
  emits: [
    "update:show",
    "update:meta",
    "set:template",
    "set:selectedData",
    "hide:searchForm",
    "fetch:lots",
    "set:mode",
    "reset:all",
  ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    meta: {
      type: Object as PropType<ExtendedTemplateI>,
      default: () => ({}),
    },
    searchForm: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
    mode: {
      type: String as PropType<'create'|'edit'|'copy'>,
      default: 'create',
    },
  },
  setup(props, context) {

    const loader = ref(false);

    const searchStore = useSearchStore();
    const templatesStore = useTemplatesStore();

    function getLabel(m: string, title: string) {
      switch (m) {
        case 'edit': return `Редактирование шаблона "${ title }"`;
        case 'copy': return `Копирование шаблона "${ title }"`;
        case 'create':
        default: return 'Сохранение нового шаблона';
      }
    }

    function getTemplateMeta() {
      switch (props.mode) {
        case 'edit': return getEditMeta(props.meta);
        case 'copy': return getCopyMeta(props.meta);
        case 'create':
        default: return getDefaultTemplateData();
      }
    }

    const templateMeta = ref<ExtendedTemplateI>(getTemplateMeta())

    function hide() {
      context.emit('update:show', false);
    }

    function resetAll() {
      context.emit('reset:all')
      hide()
    }

    /** Создание/копирование шаблона */
    function postTemplateMeta() {
      loader.value = true;

      templatesStore
        .postTemplateMeta({ meta: templateMeta.value, data: tagsToIdList(props.searchForm, 'LotsListSearch') })
        .then((template: ExtendedTemplateI) => {
          hide();
          context.emit("set:selectedData", [template.id]);
          if (searchStore.searchPageMode === 'templateMode') context.emit("set:template", { meta: template, filters: props.searchForm });
          else context.emit("set:mode", { meta: template, filters: props.searchForm })
          context.emit("hide:searchForm");
          context.emit("fetch:lots")
        })
        .catch(() => {})
        .finally(() => loader.value = false)
    }

    /** Редактирование шаблона */
    function editTemplateMeta() {
      loader.value = true;

      templatesStore
        .patchTemplateMeta({ id: templateMeta.value.id, meta: templateMeta.value, needClear: false, showNotification: true })
        .then((template: ExtendedTemplateI) => {
          context.emit('update:meta', template)
          hide()
        })
        .catch(() => {})
        .finally(() => loader.value = false)
    }

    const { validateForm, errorChecker, correctChecker }
      = useForm(props.mode === 'edit' ? editTemplateMeta : postTemplateMeta);

    return {
      loader,
      templateMeta,
      resetAll,
      hide,
      getLabel,
      validateForm,
      errorChecker,
      correctChecker,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
