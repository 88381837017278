<template lang="pug">
.lots-list
  action-panel(
    v-if="hasMultipleSelect"
    :count="selectedLotCardsCount"
    :results="data.length || 0"
    :limit="MAX"
    @all="selectAll"
    @reset="resetSelectedLots"
    @tag="selectTag"
    @status="selectStatus"
  )

  alert-message(
    v-if="showResultsWarning || searchSettingsLoader"
    type="warning"
    label="В выдаче отсутствуют закупки с установленными статусами"
  )
    template(#content)
      .warning-content
        span В настройках функциональности Вы поставили галочку 'Скрывать закупки с проставленными статусами'. Вы можете воспользоваться фильтром по статусу заявки, чтобы их увидеть, или отключить данную настройку
        ui-button.button(type="secondary" size="small" :loader="searchSettingsLoader" @click="resetSearchSettings") Отключить настройку

  list-wrapper(
    :info="info"
    :count="data.length || 0"
    @reload="$emit('reload')"
    @load-next-part="$emit('loadNextPart')"
  )
    lot-item-search(
      v-for="item of data" :key="item.lotId"
      :lot="item"
      :selected="!!selectedLotCards[item.lotId]"
      :simple-card="isFastSearch"
      :show-etp-number="showEtpNumber"
      :show-docs-fts="showDocsFTS"
      :docs-keywords="docsKeywords"
      @lot-click="onLotClick(item)"
      @seen="$emit('seen', $event)"
      @show-entry-keywords="showEntryKeywords"
      @show-create-contract="openCreateContractDialog"
    )
      template(#checkbox)
        .lot-card-checkbox(@click.stop v-if="hasMultipleSelect")
          input(
            type="checkbox"
            :id="item.lotId"
            :checked="!!selectedLotCards[item.lotId]"
            :disabled="!selectedLotCards[item.lotId] && selectedLotCardsCount >= MAX"
            @input="onCheckboxClick(item)"
            @change="onChange(item)"
          )
          label(:for="item.lotId")

dialog-entries-keywords(
  v-model:show="visibleKeywordsLot"
  :lot="entryKeywordsLot"
)
create-contract-dialog(
  v-model:show="showCreateContract"
  :cost="createContractPayload.cost"
  :responsible="createContractPayload.responsiblePerson"
  :lot-id="createContractPayload.lotId"
  @on:hide="onHideDialog"
)

confirm-action(v-if="showConfirm" v-model:show="showConfirm" :mode="confirmMode" :selected-status="selectedStatus" :selected-tag="selectedTag" :data="Object.values(selectedLotCards)" @reset="resetSelectedLots")
limit-dialog(v-if="showLimit" v-model:show="showLimit" @reset="resetSelectedLots")
</template>

<script lang="ts">
import { defineComponent, ref, defineAsyncComponent, computed, watch } from "vue";
import { getEntityListInfo } from "@/utils/getters/defaultRequestInfo";
import { useMarkViewed } from "@/use/other/useMarkViewed";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { storeToRefs } from "pinia";

import ListWrapper from "@/components/mainStreamPage/metaInfo/ListWrapper.vue";
import LotItemSearch from "@/components/cards/LotCardSearch.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";
import ActionPanel from "~/components/ui/filters/ActionPanel.vue";
import ConfirmAction from "~/components/ui/filters/ConfirmAction.vue";
import UiButton from "~/components/ui/button/UiButton.vue";

import type { PropType } from "vue";
import type { MainStreamListInfo } from "@/utils/getters/defaultRequestInfo";
import type { LotItemInterface, TenderTagI } from "@/stores/search/LotItemInterface";
import type { CostItemI, StatusI } from "@/stores/manuals/ManualsInterface";
import type { ManagerI } from "@/stores/auth/UserInterface";
import useSearchStore from "~/stores/search/useSearchStore";
import useInterfaceSettingsStore from "~/stores/settings/useInterfaceSettingsStore";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import { Tabs } from "~/router/tabs";
import { useRoute } from "vue-router";

const MAX = 40;

function getDefaultPayload() {
  return {
    responsiblePerson: null,
    cost: null,
    lotId: null,
  }
}

export default defineComponent({
  name: "LotsList",
  components: {
    UiButton,
    ConfirmAction,
    ActionPanel,
    AlertMessage,
    UiCheckbox,
    ListWrapper,
    LotItemSearch,
    DialogEntriesKeywords: defineAsyncComponent(
      () => import("@/components/mainStreamPage/lotItem/dialogs/DialogEntriesKeywords.vue")
    ),
    CreateContractDialog: defineAsyncComponent(
      () => import("@/components/mainStreamPage/lotItem/dialogs/CreateContractDialog.vue")
    ),
    LimitDialog: defineAsyncComponent(
      () => import("~/components/pages/search/dialogs/apps/LimitDialog.vue")
    ),
  },
  emits: [
    'refetch',
    'reload',
    'seen',
    'loadNextPart',
  ],
  props: {
    data: {
      type: Array as PropType<LotItemInterface[]>,
      default: () => [],
    },
    info: {
      type: Object as PropType<MainStreamListInfo>,
      default: getEntityListInfo,
    },
  },
  setup(props, context) {

    const route = useRoute();
    const showLimit = ref(false);

    const showCreateContract = ref(false);
    const createContractPayload: any = ref(getDefaultPayload());

    const selectedLotCards = ref({});
    const selectedLotCardsCount = computed(() => Object.keys(selectedLotCards.value).length)

    const { enableLotManagement, roleReadOnly } = useUserAccess();
    const { isMarkedOnClick, markAsSeen, checkNeedMarkViewed } = useMarkViewed();

    const searchStore = useSearchStore();
    const { searchPageMode, tags } = storeToRefs(searchStore);

    const interfaceStore = useInterfaceSettingsStore()
    const notificationsStore = useNotificationsStore();

    const showEtpNumber = computed(() =>
      route.name === Tabs.Search
      && !!(
        (searchStore.searchPageMode === 'simpleSearchMode' && searchStore.searchFormDataFS.govRuIds.length)
        || (searchStore.searchPageMode === 'fullSearchMode' && searchStore.searchFormDataFilters.govRuIds.length)
      ))

    const docsKeywords = computed(() => {
      if (searchStore.searchPageMode === 'simpleSearchMode') return searchStore.searchFormDataFS.docsSearch.keywords
      if (searchStore.searchPageMode === 'fullSearchMode') return searchStore.searchFormDataFilters.docsSearch.keywords
      return []
    });

    const showDocsFTS = computed(() => route.name === Tabs.Search && !!docsKeywords.value.length)

    const hasMultipleSelect = computed(() => searchPageMode.value !== 'simpleSearchMode' && (enableLotManagement.value || tags.value.length !== 0) && !roleReadOnly.value)
    const isFastSearch = computed(() => route.name === Tabs.Search && searchPageMode.value === 'simpleSearchMode')

    /** warning "скрывать закупки с проставленными статусами" */
    const searchSettingsLoader = ref(false);
    const showResultsWarning = computed(() =>
      (searchPageMode.value === 'templateMode' && interfaceStore.searchSettings.hideActiveLotsInSearchByTemplates)
      || ((searchPageMode.value === 'fullSearchMode' || searchPageMode.value === 'simpleSearchMode') && interfaceStore.searchSettings.hideActiveLotsInQuickSearch)
    )

    /** закрытие warning'а "скрывать закупки с проставленными статусами" */
    function resetSearchSettings() {
      searchSettingsLoader.value = true;

      (searchPageMode.value === 'templateMode'
        ? interfaceStore.updateSearchSettingsValue('hideActiveLotsInSearchByTemplates', false)
        : interfaceStore.updateSearchSettingsValue('hideActiveLotsInQuickSearch', false))
          .then(() => {
            searchSettingsLoader.value = false;
            notificationsStore.showSuccess(`Настройка отключена для ${ searchPageMode.value === 'templateMode' ? 'поиска по шаблонам' : 'быстрого поиска' }. Результаты поиска были обновлены`)
            context.emit('refetch')
          })
    }

    function onCheckboxClick(lot: LotItemInterface) {
      if (selectedLotCards.value[lot.lotId]) {
        delete selectedLotCards.value[lot.lotId];
      } else {
        selectedLotCards.value[lot.lotId] = lot;
      }
    }

    function onLotClick(lot: LotItemInterface) {
      if (hasMultipleSelect.value && !selectedLotCards.value[lot.lotId] && selectedLotCardsCount.value < MAX) {
        selectedLotCards.value[lot.lotId] = lot
        checkLimit()
      }
    }

    function selectAll() {
      if (props.data.length <= MAX) {
        props.data.forEach(lot => {
          if (!selectedLotCards.value[lot.lotId]) selectedLotCards.value[lot.lotId] = lot
        })
      }
    }

    function onHideDialog() {
      createContractPayload.value = getDefaultPayload();
    }

    function onChange(lot: LotItemInterface) {
      if (isMarkedOnClick.value && checkNeedMarkViewed(lot)) {
        context.emit('seen', lot)
        markAsSeen(lot)
      }
      checkLimit()
    }

    function checkLimit() {
      if (selectedLotCardsCount.value === MAX) showLimit.value = true
    }

    const entryKeywordsLot = ref<LotItemInterface>();
    const visibleKeywordsLot = ref(false);

    function showEntryKeywords(lot: LotItemInterface) {
      entryKeywordsLot.value = lot;
      visibleKeywordsLot.value = true;
    }

    function openCreateContractDialog(payload: { responsiblePerson: ManagerI, cost: CostItemI, lotId: number }) {
      createContractPayload.value = payload;
      showCreateContract.value = true;
    }

    function resetSelectedLots(lotsToSelect?: any[]) {
      selectedLotCards.value = {}
      if (lotsToSelect) lotsToSelect.forEach(lot => {
        if (!selectedLotCards.value[lot.lotId]) selectedLotCards.value[lot.lotId] = lot
      })
    }

    watch(() => props.info.meta.part, (newValue: number) => {
      if (newValue === 0) resetSelectedLots()
    })

    watch(searchPageMode, () => resetSelectedLots())

    const showConfirm = ref(false);
    const confirmMode = ref('');

    const selectedTag = ref<TenderTagI|null>(null);
    const selectedStatus = ref<StatusI|null>(null);

    function selectStatus(status: StatusI) {
      confirmMode.value = 'status'
      selectedStatus.value = status
      selectedTag.value = null
      showConfirm.value = true
    }

    function selectTag(tag: TenderTagI) {
      confirmMode.value = 'tag'
      selectedStatus.value = null
      selectedTag.value = tag
      showConfirm.value = true
    }

    return {
      showEtpNumber,
      showDocsFTS,
      docsKeywords,
      showLimit,
      selectedLotCards,
      selectedLotCardsCount,
      entryKeywordsLot,
      visibleKeywordsLot,
      createContractPayload,
      showCreateContract,
      showConfirm,
      confirmMode,
      selectedTag,
      selectedStatus,
      hasMultipleSelect,
      searchSettingsLoader,
      showResultsWarning,
      isFastSearch,
      resetSearchSettings,
      selectAll,
      onChange,
      onCheckboxClick,
      onLotClick,
      showEntryKeywords,
      onHideDialog,
      openCreateContractDialog,
      resetSelectedLots,
      selectStatus,
      selectTag,
      MAX,
    };
  },
});
</script>

<style scoped lang="scss">
.lots-list {
  display: flex;
  flex-flow: column;
  gap: 16px;
}

.lot-card-checkbox {
  padding-left: 12px;
  align-self: center;

  :deep(label) {
    padding-left: 16px !important;
  }
}

.warning-content {
  display: flex;
  flex-flow: row;
  gap: 16px;

  .button {
    margin-left: auto;
    text-wrap: nowrap;

    &:hover {
      color: #ed7a1d !important;
      border: 1px solid #FFC310 !important;
      background-color: white !important;
    }
  }
}
</style>
