<template lang="pug">
#main-search
  .main-page-title
    h1 ПОИСК
    ui-radio-button.slider(
      size="large"
      :options="searchSliderOptions"
      :model-value="searchPageMode"
      @update:model-value="onChangeMode"
    )
  fast-search(
    v-if="searchPageMode === 'simpleSearchMode'"
    :has-default-changes="hasDefaultFiltersChanges"
    :no-results="fetchData && !fastSearchInfo?.meta?.loading && !fastSearchData.length"
    :show-results="!!fetchData"
    v-model:search-form="searchForm"
    @fetch:lots="fetchFastSearch"
    @template:create="openTemplateDialog"
    @form:extend="copyResultsToExtendPage"
  )
  search-by-filters(
    v-if="searchPageMode === 'fullSearchMode'"
    :has-filters-changes="hasFiltersChanges"
    :has-default-changes="hasDefaultFiltersChanges"
    v-model:search-form="searchForm"
    @fetch:lots="fetchLots"
    @template:create="openTemplateDialog"
  )
  search-by-templates(
    v-if="enableTemplates && searchPageMode === 'templateMode'"
    v-model:search-form="searchForm"
    v-model:show-error-message="showErrorMessage"
    v-model:show-search-form="showTemplateSearchForm"
    :request="request"
    :fetch-mode="info?.meta?.mode"
    :has-filters-changes="hasFiltersChanges"
    :has-default-changes="hasDefaultFiltersChanges"
    :error-message="errorMessage"
    @reset:all="clearAll"
    @template:create="openTemplateDialog"
    @select:templates="selectSearchTemplate"
    @fetch:lots="onFetchLots"
    @refetch:lots="onExitWithoutSave"
    @start:searchOnly="searchWithoutSaving"
    @set:template="setTemplateData"
    @fetch:template="fetchTemplateEntity"
  )

  #main-search-content
    list-options(
      v-if="fetchData && !hideListOptions"
      :options="searchPageMode === 'simpleSearchMode' ? fastSearchInfo?.options : info?.options"
    )
    filter-panel(
      v-if="fetchData"
      v-model="preFiltrationForm"
      type="filters"
      :columns="columns"
      :has-active-filters="hasActiveFilters"
      :is-downloading="isLotsDownloading"
      @apply="applyFilters"
      @reset="onResetClick"
      @download="downloadLots"
    )
    lots-list(
      v-if="fetchData"
      :data="searchPageMode === 'simpleSearchMode' ? fastSearchData : data"
      :info="searchPageMode === 'simpleSearchMode' ? fastSearchInfo : info"
      @refetch="applyFilters"
      @reload="updateTemplateOutput"
      @seen="onSeen"
      @load-next-part="updateTemplateOutput"
    )

template-meta-dialog(
  v-if="showTemplateMetaDialog"
  v-model:show="showTemplateMetaDialog"
  v-model:meta="selectedTemplateMeta"
  :mode="templateMetaMode"
  :search-form="searchForm"
  @set:template="setTemplateData"
  @set:selected-data="setSelectedTemplateIds"
  @fetch:lots="onFetchLots(true)"
  @reset:all="clearAll"
  @set:mode="openTemplatePage"
  @hide:searchForm="showTemplateSearchForm = false"
)

group-meta-dialog(v-if="showModifyGroupDialog" v-model:show="showModifyGroupDialog" :meta="group" :edit-mode="modifyGroupMode === 'rename'" @resolve="onResolve")
delete-group-dialog(v-if="showDeleteGroupDialog" v-model:show="showDeleteGroupDialog" :group="group" @resolve="onGroupDelete" @refetch="selectSearchTemplate" @reset:all="clearAll")
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, provide, computed, defineAsyncComponent } from "vue";
import { scrollIntoView } from "@/utils/scroller/documentScroller";
import { useLotsList } from "@/components/pages/search/useLotsList";
import { useFiltersPanel } from "@/use/filters/useFiltersPanel";
import { getDefaultPreFiltrationForm } from "@/utils/getters/defaultFilters";
import { useSearchGlobals } from "~/use/search/useSearchGlobals";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { useRoute, useRouter } from "vue-router";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";

import useTemplatesStore from "@/stores/templates/useTemplatesStore";

import UiRadioButton from "~/components/ui/radio/UiRadioButton.vue";
import FastSearch from "~/components/pages/search/tabs/FastSearch.vue";
import SearchByFilters from "~/components/pages/search/tabs/SearchByFilters.vue";
import SearchByTemplates from "~/components/pages/search/tabs/SearchByTemplates.vue";
import ListOptions from "@/components/mainStreamPage/metaInfo/ListOptions.vue";
import FilterPanel from "@/components/ui/filters/FilterPanel.vue";
import LotsList from "@/components/pages/search/LotsList.vue";
import TemplateMetaDialog from "@/components/pages/search/dialogs/TemplateMetaDialog.vue";

import UiIconNames from "@/components/ui/icon/UiIconNames";
import type { LotItemInterface } from "~/stores/search/LotItemInterface";

export default defineComponent({
  name: "SearchPageIndex",
  components: {
    UiRadioButton,
    FastSearch,
    SearchByFilters,
    SearchByTemplates,
    ListOptions,
    FilterPanel,
    LotsList,
    TemplateMetaDialog,
    GroupMetaDialog: defineAsyncComponent(() => import("~/components/pages/search/dialogs/group/GroupMetaDialog.vue")),
    DeleteGroupDialog: defineAsyncComponent(() => import("~/components/pages/search/dialogs/group/DeleteGroupDialog.vue")),
  },
  setup() {

    provide("type", "LotsListSearch");

    const {
      data,
      info,
      fastSearchData,
      fastSearchInfo,
      fetchFastSearch,
      searchPageMode,
      request,
      fetchMode,
      isLotsDownloading,
      preFiltrationForm,
      fetchData,
      searchForm,
      hasFiltersChanges,
      hasDefaultFiltersChanges,
      selectedTemplateMeta,
      errorMessage,
      showErrorMessage,
      showTemplateSearchForm,
      loadData,
      selectSearchTemplate,
      setTemplateData,
      setTemplateDataBeforeLoad,
      openTemplatePage,
      setSelectedTemplateIds,
      hideListOptions,
      fetchLots,
      fetchTemplateEntity,
      fetchLotsWithoutSaving,
      updateTemplateOutput,
      applyFilters,
      downloadLots,
      clearPageData,
      onChangeMode,
      copyResultsToExtendPage,
    } = useLotsList();

    const templatesStore = useTemplatesStore()

    const { enableTemplates, isTmManager } = useUserAccess()

    const searchSliderOptions = computed(() => [
      { id: 'simpleSearchMode', label: 'Быстрый поиск', value: 'simpleSearchMode' },
      { id: 'fullSearchMode', label: 'Расширенный поиск', value: 'fullSearchMode' },
      (isTmManager.value || (enableTemplates.value && templatesStore.templatesList.length)) && { id: 'templateMode', label: 'Поиск по шаблонам', value: 'templateMode' },
    ].filter(Boolean))

    /** TEMPLATES */
    const templateMetaMode = ref<'create'|'copy'|'edit'>('create');         // mode для диалога templateMetaEditor
    const showTemplateMetaDialog = ref(false);            // диалог создания/копирования/редактирования мета данных шаблона

    /** открытие диалога - создать/редактировать мета данные шаблона */
    function openTemplateDialog(mode = 'create') {
      templateMetaMode.value = mode;
      showTemplateMetaDialog.value = true;
    }

    function onSeen(lot: LotItemInterface) {
      // todo попросить Женю присылать мне валюту, в которой приходит сапп
      if (info.value?.options?.info?.unseenCountLots) info.value.options.info.unseenCountLots--;
      if (info.value?.options?.info?.unseenSumCost)
        info.value.options.info.unseenSumCost = info.value.options.info.unseenSumCost - (lot.cost.converted ? lot.cost.converted.cost : lot.cost.original.cost);
    }

    const route = useRoute();
    const router = useRouter();

    const { storage } = useServiceStorage();

    onMounted(() => {
      if (storage.openNewSearch) {
        storage.openNewSearch = false
        searchPageMode.value = 'fullSearchMode'

        clearPageData()
      }

      if (route.query.templateId) {
        const t = Number(route.query.templateId)
        const to = route.query.dateCreateTo
        const from = route.query.dateCreateFrom

        const dateCreate = (to || from) ? {
          dateFrom: from,
          dateTo: to,
        } : undefined;

        router.replace({ query: {} });

        setTemplateDataBeforeLoad(t, dateCreate)
      }

      loadData()
    })

    const {
      group,
      modifyGroupMode,
      onResolve,
      showDeleteGroupDialog,
      showModifyGroupDialog,
    } = useSearchGlobals();

    function onGroupDelete(e?: any) {
      onResolve(e)
    }

    const defaultFiltrationForm = getDefaultPreFiltrationForm();
    const {
      searchColumns: columns,
      hasActiveFilters,
      onResetClick,
    } = useFiltersPanel(preFiltrationForm, defaultFiltrationForm, applyFilters);

    /** поиск по фильтрам при редактировании шаблона (без сохранения) */
    function searchWithoutSaving() {
      fetchLotsWithoutSaving()
      scrollIntoView('main-search-content', 'start');
    }

    function clearAll() {
      clearPageData()
      showErrorMessage.value = false;
    }

    function onFetchLots(firstSearch: boolean) {
      showErrorMessage.value = false
      fetchTemplateEntity(firstSearch)
    }

    function onExitWithoutSave() {
      showErrorMessage.value = false
      info.value.meta.mode = "byTemplate"
      fetchMode.value[searchPageMode.value] = "byTemplate"
      applyFilters(true)
    }

    return {
      data,
      info,
      fastSearchData,
      fastSearchInfo,
      hideListOptions,
      onSeen,
      searchPageMode,
      searchSliderOptions,
      enableTemplates,
      onChangeMode,
      fetchFastSearch,
      copyResultsToExtendPage,
      onResolve,
      onExitWithoutSave,
      columns,
      onResetClick,
      hasActiveFilters, // preFiltrationForm
      modifyGroupMode,
      showModifyGroupDialog,
      showDeleteGroupDialog,
      group,
      onGroupDelete,
      request,
      searchForm,
      selectedTemplateMeta,
      templateMetaMode,
      fetchData,
      errorMessage,
      preFiltrationForm,
      isLotsDownloading,
      showErrorMessage,
      showTemplateMetaDialog,
      hasDefaultFiltersChanges,
      hasFiltersChanges,
      showTemplateSearchForm,
      searchWithoutSaving,
      selectSearchTemplate,
      setTemplateData,
      setSelectedTemplateIds,
      openTemplatePage,
      clearAll,
      applyFilters,
      fetchLots,
      onFetchLots,
      fetchTemplateEntity,
      updateTemplateOutput,
      openTemplateDialog,
      downloadLots,                   // скачивание результатов
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#main-search {
  @include page-view;
}

.main-page-title {
  display: flex;
  flex-flow: row;
  gap: 8px;

  padding-bottom: 24px !important;
  align-items: center;

  .slider {
    margin-left: auto;
    width: fit-content;
  }
}
</style>
