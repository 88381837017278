<template lang="pug">
.search-page
  search-templates-selector(
    v-model:showSearchForm="showSearchFormModel"
    :request="request"
    :search-form="searchForm"
    @reset:all="$emit('reset:all')"
    @show:template-dialog="openTemplateDialog"
    @set:templates="$emit('select:templates', $event)"
  )

  alert-message(
    v-if="dateCreateTemplateFilter"
    label="Ограничение по дате"
    :message="dateCreateTemplateMessage"
  )
    span.show-all-button(@click="$emit('fetch:template', false)") Показать все найденные лоты

  alert-message(
    v-if="showErrorMessage && errorMessage && errorMessage?.type === 'template'"
    closable
    type="error"
    :label="errorMessage.label"
    :message="errorMessage.message"
    @close="$emit('update:showErrorMessage', false)"
  )

  search-form(
    v-if="showSearchFormModel"
    tab="LotsListSearch"
    warning-type="byTemplate"
    :has-filters-changes="hasFiltersChanges"
    :has-default-changes="hasDefaultChanges"
    :edit-mode="true"
    :show-hide-button="false"
    :by-template="true"
    v-model:showSearchForm="showSearchFormModel"
    v-model:searchForm="searchFormModel"
    v-model:activeTab="searchFormActiveTab"
    @start:searchOnly="$emit('start:searchOnly')"
    @form:exit="exitWithoutSave"
    @save:template="saveNewTemplate"
    @save:changes="onUpdateTemplate"
  )

need-clear-dialog(
  v-model:show="showUpdateTemplateDialog"
  :meta="selectedTemplateMeta"
  :search-form="searchForm"
  @set:template="$emit('set:template', $event)"
  @set:selected-data="setSelectedTemplateIds"
  @fetch:lots="$emit('fetch:lots', $event)"
)
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref } from "vue";
import { tagsToIdList, validateSearchForm } from "~/utils/searchForm/searchFormWorker";
import { scrollIntoView } from "~/utils/scroller/documentScroller";
import { useVModel } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { formatDateTime } from "~/utils/formatter/dateFormatter";

import useSearchStore from "~/stores/search/useSearchStore";
import useTemplatesStore from "~/stores/templates/useTemplatesStore";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import useInterfaceSettingsStore from "~/stores/settings/useInterfaceSettingsStore";
import cloneDeep from "lodash/cloneDeep";

import SearchTemplatesSelector from "~/components/selector/templateSelector/SearchTemplatesSelector.vue";
import SearchForm from "~/components/searchForm/SearchForm.vue";
import NeedClearDialog from "~/components/pages/search/dialogs/NeedClearDialog.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";

import type { ExtendedTemplateI } from "~/stores/templates/SearchTemplatesInterface";
import type { SearchFormI } from "~/stores/search/SearchFormInterface";
import type { RequestCommonInfo } from "~/utils/getters/defaultRequestInfo";

export default defineComponent({
  name: "SearchByTemplates",
  components: {
    NeedClearDialog,
    SearchForm,
    AlertMessage,
    SearchTemplatesSelector,
  },
  emits: [
    'template:create',
    'reset:all',
    'select:templates',
    'fetch:lots',
    'refetch:lots',
    'start:searchOnly',
    'set:template',
    'fetch:template',
    'update:searchForm',
    'update:showErrorMessage',
    'update:showSearchForm',
  ],
  props: {
    searchForm: {
      type: Object as PropType<SearchFormI>,
      default: () => ({}),
    },
    request: {
      type: Object as PropType<RequestCommonInfo>,
      default: () => ({}),
    },
    fetchMode: {
      type: String,
      default: '',
    },
    hasFiltersChanges: {
      type: Boolean,
      default: false,
    },
    hasDefaultChanges: {
      type: Boolean,
      default: false,
    },
    showErrorMessage: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: Object,
      default: () => ({}),
    },
    showSearchForm: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const searchStore = useSearchStore()
    const templatesStore = useTemplatesStore()
    const interfaceStore = useInterfaceSettingsStore()
    const notificationStore = useNotificationsStore()

    const { selectedTemplateMeta, searchFormDataTemplates, selectedTemplatesIds, dateCreateFilter } = storeToRefs(searchStore);
    const { setSelectedTemplateIds } = searchStore;

    const searchFormActiveTab = ref('default');
    const showUpdateTemplateDialog = ref(false);          // диалог редактирования фильтров шаблона

    const searchFormModel = useVModel(props, 'searchForm', context.emit)
    const showSearchFormModel = useVModel(props, 'showSearchForm', context.emit)

    const dateCreateTemplateFilter = computed(() =>
      selectedTemplatesIds.value.length === 1 && ![-1, -2].includes(selectedTemplatesIds.value[0]) && dateCreateFilter.value
        ? dateCreateFilter.value
        : null
    )

    const dateCreateTemplateMessage = computed(() => {
      const from = dateCreateTemplateFilter.value.dateFrom ? 'c ' + formatDateTime(dateCreateTemplateFilter.value.dateFrom) : '';
      const to = dateCreateTemplateFilter.value.dateTo ? 'до ' + formatDateTime(dateCreateTemplateFilter.value.dateTo) : '';
      return `Шаблон был открыт по ссылке из почтовой рассылки. Результат поиска содержит найденные лоты, добавленные в базу ${ from } ${ to }`;
    })

    /** открытие диалога - сохранить новый шаблон */
    function saveNewTemplate() {
      if (props.hasDefaultChanges) {
        const notValid = validateSearchForm(searchFormModel.value)
        if (notValid) useNotificationsStore().showAlert('Ошибка валидации фильтров', notValid)
        else openTemplateDialog('create')
      } else {
        useNotificationsStore().showAlert('Пустой запрос', 'Пожалуйста, установите поисковые фильтры прежде, чем сохранять шаблон', 6000)
      }
    }

    /** открытие диалога - копировать/редактировать мета данные шаблона */
    function openTemplateDialog(mode: 'copy'|'edit'|'create') {
      context.emit('template:create', mode)
    }

    /** открытие диалога - обновить расширенные фильтры шаблона */
    function onUpdateTemplate() {
      if (interfaceStore.functions.rememberClearSetting) saveCurrentTemplate(interfaceStore.functions.needClearBeforeSave)
      else showUpdateTemplateDialog.value = true;
    }

    /** сохранить изменения выбранных фильтров (при редактировании выбранного шаблона, без мета данных) */
    function saveCurrentTemplate(needClear = false) {
      templatesStore.patchTemplateMeta({ id: selectedTemplateMeta.value?.id, meta: selectedTemplateMeta.value, data: tagsToIdList(searchFormModel.value, 'LotsListSearch'), needClear, showNotification: true })
        .then((template: ExtendedTemplateI) => {
          setSelectedTemplateIds([template.id]);
          context.emit('set:template', { meta: template, filters: searchFormModel.value })
          context.emit('fetch:template', true);
          scrollIntoView('main-search-content', 'start');
        })
        .catch(() => {})
    }

    function exitWithoutSave() {
      showSearchFormModel.value = false
      searchFormActiveTab.value = 'default'
      searchFormModel.value = cloneDeep(searchFormDataTemplates.value)

      if (props.fetchMode === 'withoutSaving') context.emit('refetch:lots')
    }

    return {
      searchFormModel,
      selectedTemplateMeta,
      showSearchFormModel,
      showUpdateTemplateDialog,
      searchFormActiveTab,
      setSelectedTemplateIds,
      dateCreateTemplateFilter,
      dateCreateTemplateMessage,
      exitWithoutSave,
      saveNewTemplate,
      openTemplateDialog,
      onUpdateTemplate,
    };
  },
});
</script>

<style scoped lang="scss">
.search-page {
  display: flex;
  flex-flow: column;
  gap: 24px;

  margin-bottom: 40px;
}

.show-all-button,
.open-form-button {
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
