<template lang="pug">
ui-dialog(
  modal
  width="560px"
  :visible-prop="show"
  header="Обновление шаблона"
  @content:hide="hide"
)
  template(#content)
    ui-checkbox(v-model="needClear" id="need-clear" label="Удалить старые результаты шаблона")
    ui-checkbox(v-if="!isTmManager" v-model="remember" id="hide-on-next-call" label="Запомнить выбор и больше не спрашивать")
    warning-message
      | При удалении результатов, в шаблон перезапишутся все найденные на текущий момент лоты, удовлетворяющие фильтрам.
      |
      | При сохранении результатов, уже найденные лоты остаются привязанными к редактируемому шаблону.
    span.error(v-if="error") При обновлении шаблона произошла ошибка
  template(#actions)
    ui-button(type="secondary" @click.stop="hide") Отменить
    ui-button(:loader="loader" @click.stop="saveChanges") Сохранить
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { tagsToIdList } from "@/utils/searchForm/searchFormWorker";
import { scrollIntoView } from "@/utils/scroller/documentScroller";

import UiDialog from "@/components/ui/dialog/UiDialog.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import WarningMessage from "@/components/ui/alert/WarningMessage.vue";

import type { PropType } from 'vue';
import type { ExtendedTemplateI } from "@/stores/templates/SearchTemplatesInterface";
import useTemplatesStore from "@/stores/templates/useTemplatesStore";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";

export default defineComponent({
  name: "NeedClearDialog",
  components: {
    WarningMessage,
    UiCheckbox,
    UiDialog,
    UiButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    meta: {
      type: Object as PropType<ExtendedTemplateI>,
      default: () => ({}),
    },
    searchForm: {
      type: Object as PropType<ExtendedTemplateI>,
      default: () => ({}),
    },
  },
  emits: [
    'update:show',
    'save:changes',
    "set:template",
    "set:selectedData",
    "fetch:lots",
  ],
  setup(props, context) {

    const loader = ref(false);
    const error = ref(false);

    const needClear = ref(false);
    const remember = ref(false);

    const { isTmManager } = useUserAccess();
    const templatesStore = useTemplatesStore();

    const interfaceStore = useInterfaceSettingsStore();

    function hide() {
      context.emit('update:show', false);
    }

    function saveChanges() {
      if (remember.value) {
        interfaceStore.updateFunctionsValue('needClearBeforeSave', needClear.value)
        interfaceStore.updateFunctionsValue('rememberClearSetting', remember.value)
      }
      saveCurrentTemplate(needClear.value)
    }

    /** сохранить изменения выбранных фильтров (при редактировании выбранного шаблона, без мета данных) */
    function saveCurrentTemplate(needClear = false) {
      loader.value = true;
      error.value = false;

      templatesStore.patchTemplateMeta({ id: props.meta?.id, meta: props.meta, data: tagsToIdList(props.searchForm, 'LotsListSearch'), needClear })
        .then((template: ExtendedTemplateI) => {
          hide()
          context.emit("set:selectedData", [template.id]);
          context.emit("set:template", { meta: template, filters: props.searchForm });
          context.emit("fetch:lots", true)
          scrollIntoView('main-search-content', 'start');
        })
        .catch(() => error.value = true)
        .finally(() => loader.value = false)
    }

    return {
      loader,
      error,
      needClear,
      remember,
      isTmManager,
      hide,
      saveChanges,
    };
  },
})
</script>

<style scoped lang="scss">
.error {
  font-size: 12px;
  color: var(--main-red-color);
}
</style>
