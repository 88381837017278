<template lang="pug">
.selectors
  form-input-container(label="Сохраненные шаблоны")
    template-selector.templates-selector(
      show-lots
      ref="templateSelector"
      v-model="selectedTemplatesIds"
      @accept="selectTemplatesPrev"
      @clear:select="clearSelect"
      @delete="onDeleteClick"
    )
      mini-loader(v-if="selectedTemplateId && request.loading")

  template(v-for="action of actions" :key="action.key" v-if="!roleReadOnly && !request.loading && !request.error")
    icon-button.fix-height(
      v-if="action.visible && action.type === 'icon'"
      as-button
      button-type="tertiary"
      :size="16"
      :icon="action.icon"
      :tooltip-title="action.tooltip"
      @click.stop="action.action"
    )
    ui-tooltip(v-if="action.visible && action.type === 'button'" :content="action.tooltip" :max-width="240")
      ui-button.fix-height(
        type="tertiary"
        :icon-left="action.icon"
        @click.stop="action.action"
      ) {{ action.text }}

pop-confirm(
  v-model:show="showPopConfirm"
  @event="popConfirmEvent"
)
delete-meta-dialog(
  v-model:show="showDeleteMeta"
  :ids="templatesIdsForDeleting"
  @refetch="selectTemplatesPrev"
  @reset:all="clearSelect"
)
templates-modify-dialog(
  v-if="showTemplateListEditor"
  v-model:show="showTemplateListEditor"
  :mode="templateListEditorMode"
  :templates="selectedTemplatesIds"
)
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, ref, computed, } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { useTemplateChanges } from "@/use/search/useTemplateChanges";
import { scrollToTop } from "@/utils/scroller/documentScroller";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";

import IconButton from "@/components/ui/button/IconButton.vue";
import TemplateSelector from "@/components/selector/templateSelector/TemplateSelector.vue";
import OldTooltip from "~/components/ui/tooltip/OldTooltip.vue";
import MiniLoader from "@/components/ui/loader/MiniLoader.vue";
import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";
import UiButton from "~/components/ui/button/UiButton.vue";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import type { RequestCommonInfo } from "@/utils/getters/defaultRequestInfo";

import { Tabs } from "@/router/tabs";
import { EXPERT_TEMPLATE } from "@/const/templates";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import UiTooltip from "~/components/ui/tooltip/UiTooltip.vue";

export default defineComponent({
  name: "SearchTemplatesSelector",
  components: {
    UiTooltip,
    UiButton,
    IconButton,
    MiniLoader,
    OldTooltip,
    FormInputContainer,
    TemplateSelector,
    PopConfirm: defineAsyncComponent(() => import("@/components/other/PopConfirm.vue")),
    DeleteMetaDialog: defineAsyncComponent(() => import("@/components/pages/search/dialogs/DeleteMetaDialog.vue")),
    TemplatesModifyDialog: defineAsyncComponent(() => import("@/components/pages/search/dialogs/TemplatesModifyDialog.vue")),
  },
  props: {
    showSearchForm: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Object as PropType<RequestCommonInfo>,
      default: () => ({}),
    },
    searchForm: {
      type: Object as PropType<BaseSearchFormInterface>,
      required: true,
    },
  },
  emits: [
    "update:showSearchForm",
    "show:templateDialog",
    "reset:all",
    "set:templates",
  ],
  setup(props, context) {

    const templateSelector = ref<any>(null);
    const templatesIdsForDeleting = ref<number[]>([]);

    const templateListEditorMode = ref('');
    const showTemplateListEditor = ref(false);

    function hideSelector() {
      if (templateSelector.value) templateSelector.value.hideSelector();
    }

    const {
      roleReadOnly,
      isTmManager,
    } = useUserAccess()

    const {
      showPopConfirm,
      selectedTemplateId,
      selectedTemplateMeta,
      selectedTemplatesIds,
      popConfirmEvent,
      checkTemplateChanges,
    } = useTemplateChanges();

    const actions = computed(() => [
      { key: 'template-filters', visible: !!selectedTemplateId.value && selectedTemplateMeta.value?.type !== EXPERT_TEMPLATE, tooltip: 'Редактировать сохраненные фильтры шаблона', text: 'Редактировать фильтры', icon: UiIconNames.Icon_Setup, type: 'button', action: onEditFiltersClick },
      { key: 'template-edit', visible: !!selectedTemplateId.value, tooltip: 'Переименовать шаблон, изменить выбор группы, назначить ответственного и время рассылки уведомлений', text: 'Изменить прочие данные', icon: UiIconNames.Icon_Edit, type: 'button', action: onEditMetaClick },
      { key: 'template-copy', visible: !!selectedTemplateId.value && selectedTemplateMeta.value?.type !== EXPERT_TEMPLATE, tooltip: 'Копировать шаблон', icon: UiIconNames.Icon_Copy, type: 'icon', action: onCopyClick },
      { key: 'template-groups', visible: selectedTemplatesIds.value.length > 1, tooltip: 'Назначить/снять группу выбранным шаблонам', icon: UiIconNames.Icon_Folder, type: 'icon', action: () => { templateListEditorMode.value = 'group'; showTemplateListEditor.value = true; } },
      { key: 'template-responsible', visible: selectedTemplatesIds.value.length > 1, tooltip: 'Назначить/снять ответственного выбранным шаблонам', icon: UiIconNames.Icon_User, type: 'icon', action: () => { templateListEditorMode.value = 'responsible'; showTemplateListEditor.value = true; } },
      { key: 'template-mailing', visible: selectedTemplatesIds.value.length > 1, tooltip: 'Назначить/снять время уведомлений выбранным шаблонам', icon: UiIconNames.Icon_Mail, type: 'icon', action: () => { templateListEditorMode.value = 'mailing'; showTemplateListEditor.value = true; } },
      { key: 'template-delete', visible: selectedTemplatesIds.value.length > 1 || (selectedTemplatesIds.value.length === 1 && selectedTemplatesIds.value[0] !== -2 && selectedTemplateMeta.value?.type !== EXPERT_TEMPLATE), tooltip: selectedTemplatesIds.value.length > 1 ? 'Удалить шаблоны' : 'Удалить шаблон', icon: UiIconNames.Icon_Delete, type: 'icon', action: onDeleteSelectorClick },
    ])

    const showDeleteMeta = ref(false);            // диалог удаления шаблона/группы шаблонов

    function checkChanges(next: Function) {
      // если выбран шаблон проверить сохранены ли изменения
      selectedTemplateId.value ? checkTemplateChanges(props.searchForm, next) : next()
    }

    onBeforeRouteLeave((to, from, next) => {
      if (to.name !== Tabs.Landing) checkChanges(next)
      else next()
    });

    /**
     * Очистить селектор шаблонов
     */
    function clearSelect() {
      context.emit("update:showSearchForm", false);
      context.emit("reset:all")
    }

    const selectTemplatesPayload = ref({ templates: [] });

    /**
     * Выбрать шаблоны в селекторе
     */
    function selectTemplatesPrev(payload: any) {
      selectTemplatesPayload.value = payload
      checkChanges(selectTemplates)
    }

    function selectTemplates() {
      context.emit("update:showSearchForm", false);
      context.emit("reset:all");
      context.emit("set:templates", selectTemplatesPayload.value)
      scrollToTop()
    }

    /** ОТКРЫТИЕ ДИАЛОГОВ */

    function openEditTemplateMetaDialog() {
      context.emit('show:templateDialog', 'edit')
    }

    function openCopyTemplateDialog() {
      context.emit('show:templateDialog', 'copy')
    }

    /** ДЕЙСТВИЯ ИКОНОК */

    /** Копирование шаблона */
    function onCopyClick() {
      checkChanges(openCopyTemplateDialog)
      hideSelector()
    }

    /** Редактирование мета данных шаблона/группы шаблонов */
    function onEditMetaClick() {
      openEditTemplateMetaDialog()
      hideSelector()
    }

    /** Редактирование фильтров шаблона */
    function onEditFiltersClick() {
      context.emit("update:showSearchForm", true);
      hideSelector()
    }

    /** Удаление шаблона/группы шаблона */
    function onDeleteClick(ids?: number[]) {
      templatesIdsForDeleting.value = (ids ? ids : selectedTemplatesIds.value) || []
      showDeleteMeta.value = true
      hideSelector()
    }

    function onDeleteSelectorClick() {
      // костыль, иначе если напрямую передать, то вместо ids? сунется event и все сломает
      onDeleteClick()
    }

    return {
      actions,
      onDeleteClick,
      templatesIdsForDeleting,
      templateSelector,
      selectedTemplateId,
      selectedTemplatesIds,
      showDeleteMeta,
      showPopConfirm,
      roleReadOnly,
      isTmManager,
      clearSelect,
      selectTemplatesPrev,
      showTemplateListEditor,
      templateListEditorMode,
      popConfirmEvent,
      UiIconNames,
      EXPERT_TEMPLATE,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/other/other";
@import "@/assets/styles/pages/main";

::v-deep(.el-tree-node__content) {
  min-height: 36px;
}

.lot-count {
  margin: 0 4px;
  width: 60px;
}

.action-row {
  display: flex;
  flex-flow: row;
  gap: 8px;
}

.fast-search {
  display: flex;
  gap: 8px;
  width: 100%;

  :deep(.on-icon) {
    width: 100%;
  }
}

.warning {
  margin-left: 4px;
}

.templates-selector {
  width: 420px;
}

.selectors {
  display: flex;
  gap: 8px;
  align-items: flex-end;

  .template_action__wrapper {
    display: flex;
    align-items: center;
    height: 26px;
  }

  .action-group {
    display: flex;
    align-items: center;
  }

  :deep(.icon-search) {
    height: 44px !important;
    white-space: nowrap !important;
  }

  :deep(#tag-editor) {
    height: auto;
    min-height: 44px;
    width: 100%;
    padding: 6px 16px;
    align-content: center;
    border-color: var(--input-border-color);

    .tag-input {
      width: 40px;
    }
  }

  .fix-height {
    height: 44px;
    border: 1px solid #c6e2ff !important;
    color: var(--main-color-black) !important;
  }

  :deep(.fix-height) {
    height: 44px;
    text-wrap: nowrap;
    border: 1px solid #c6e2ff !important;
    color: var(--main-color-black) !important;
  }
}
</style>
